<template>
  <component
    :is="to ? NuxtLink : 'button'"
    class="button"
    :class="{
      'button-primary': ['primary', 'submit'].includes(type ?? ''),
      'button-secondary': type === 'secondary',
      'button-tertiary': type === 'tertiary',
      'opacity-50 saturate-0': disabled,
      'px-3 py-2': size === 'small',
    }"
    :to="to"
    :target="openInNewTab ? '_blank' : undefined"
    :type="type === 'submit' ? 'submit' : 'button'"
    :disabled="isDisabled"
    @click="$emit('click')"
  >
    <slot>
      <span
        v-if="($slots.default || label)"
        class="text-base"
      >{{ label }}
      </span>
    </slot>

    <UiIcon
      v-if="icon"
      :size="size"
      :name="icon"
    />
  </component>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';
import {NuxtLink} from '#components';

const props = defineProps<{
  active?: boolean
  disabled?: boolean
  icon?: string
  label?: string
  loading?: boolean
  shape?: 'round'
  size?: 'small'
  to?: RouteLocationRaw
  openInNewTab?: boolean
  type?: 'primary' | 'secondary' | 'tertiary' | 'submit'
}>();

const isDisabled = computed(() => props.disabled || props.loading);

defineEmits<{
  (e: 'click'): void
}>();
</script>
